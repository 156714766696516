<template>
  <div :class="{ 'background-light': isDispositionDifferent }">
    <div
      class="home-project container"
      :class="{ 'disposition-reverse': isDispositionDifferent }"
    >
      <div class="home-project__img">
        <img class="sphere" src="./../assets/img/home/Sphere.png" />
        <img class="sphere1" src="./../assets/img/home/Sphere-1.png" />
        <img class="sphere2" src="./../assets/img/home/Sphere-2.png" />
        <img
          style="width: 100%; position: relative; z-index: 5"
          :src="require(`@a/img/home/${img}`)"
          :alt="`${title} project`"
        />
      </div>
      <div class="home-project__block-text">
        <h2 class="home-project__title">
          {{ title }}
        </h2>
        <div v-html="$t(`${details}`)" class="home-project__details"></div>
        <p class="home-project__content" v-html="$t(`${description}`)">
      
        </p>
        <router-link :to="`/${slug}`" class="home-project__button"
          >{{ $t('home.projects.button')}}</router-link
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Project",
  props: {
    img: String,
    details: String,
    title: String,
    description: String,
    slug: String,
    isDispositionDifferent: Boolean,
    isGoogleProject: Boolean,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
@import "@a/css/variables.scss";
@import "@a/css/mixins.scss";

.sphere,
.sphere1,
.sphere2 {
  position: absolute;
}
@media (max-width:992px) {
  .sphere {
    top: 50px;
    right: 30px;
    z-index: 5;
    width: 22px;
    animation: moveRandom2 5s infinite linear;
  }
  
  .sphere1 {
    top: 80%;
    right: 30px;
    z-index: 10;
    width: 13px;
    animation: moveRandom 4s infinite linear;
  }
  .sphere2 {
    top: 30px;
    left: 37px;
    z-index: 10;
    width: 25px;
    animation: moveRandom 6s infinite linear;
  }
  
}
@media (min-width:992px) {
  .sphere {
    top: 50px;
    right: 30px;
    z-index: 5;
    width: 44px;
    animation: moveRandom2 5s infinite linear;
  }
  
  .sphere1 {
    top: 80%;
    right: 30px;
    z-index: 10;
    width: 26px;
    animation: moveRandom 4s infinite linear;
  }
  .sphere2 {
    top: 30px;
    left: 37px;
    z-index: 10;
    width: 47px;
    animation: moveRandom 6s infinite linear;
  }
  
}

@keyframes moveRandom {
  0% {
    transform: rotate(0deg) translate(-16px) rotate(0deg);
  }
  100% {
    transform: rotate(360deg) translate(-16px) rotate(-360deg);
  }
}
@keyframes moveRandom2 {
  0% {
    transform: rotate(0deg) translate(-16px) rotate(0deg);
  }
  100% {
    transform: rotate(360deg) translate(-16px) rotate(-360deg);
  }
}
.background-light {
  background: #f0f1ff;
  position: relative;
  z-index: 2;
}
.home-project {
  display: flex;
  gap: 48px;
  align-items: center;
  padding: 72px 0 !important;
  position: relative;
  &.disposition-reverse {
    flex-direction: row-reverse;
  }
  @media (max-width: 768px) {
    padding: 48px 0 !important;
    flex-direction: column !important;
    gap: 16px;
  }
  .home-project > div {
    @media (max-width: 768px) {
      text-align: center;
    }
  }
  .home-project__img {
    position: relative;
    width: 100%;
    @media (max-width: 768px) {
      width: 90%;
      max-width: 100%;
    }
  }

  .home-project__details {
    color: #7452e7;
    @include content;
    padding-top: 8px;
  }

  .home-project__button {
    border-radius: 26px;
    padding: 8px 48px;
    color: #ffffff;
    transition: all 0.5s;
    display: inline-block;
    font-family: Lato;
    font-size: 1.7rem;
    background: #7452e7;
    box-shadow: 5px 5px 15px rgba(69, 92, 215, 0.4);
    transition: all 0.5s;
    display: inline-block;
    position: relative;
    width: 205px;
    @media (max-width: 992px) {
      width: auto;
      font-size: 2rem;
    }
    &:after {
      content: url("./../assets/img/icons/arrow.svg");
      position: absolute;
      opacity: 0;
      top: 8px;
      right: -24px;
      transition: 0.5s;
      color: $white;
      @media (max-width: 992px) {
        content: "";
      }
    }
    &:hover {
      background-image: linear-gradient(
        270deg,
        #ec6ead 12.36%,
        #5c4cf2 61.26%,
        #4568dc 96.2%
      );
      padding: 8px 32px;
      @media (max-width: 992px) {
        padding: 8px 48px;
      }
      &:after {
        opacity: 1;
        right: 24px;
      }
    }
  }
}
.home-project__block-text {
  padding: 24px;
}
.home-project__separator {
  height: 1px;
  width: 100%;
  background: $grey;
}
.home-project__title {
  font-size: 5.8rem;
  font-family: Lato;
  font-weight: 900;
  line-height: 72px;
  position: relative;
  z-index:10;
  display: inline-block;

  &::before {
    background-position-x: 65%;
    content: "";
    width: calc(100% - 24px);
    height: 18px;
    position: absolute;
    bottom: 8px;
    background-size: 200% 100%;
    transition: background-position 0.7s;
    z-index: -1;
  }
}
.home-project__title {
  font-family: "Titan One";
  font-size: 4rem;
  &:before {
    background-image: linear-gradient(
      270deg,
      #ec6ead 12.36%,
      #5c4cf2 61.26%,
      #4568dc 96.2%
    );
  }
  &:after {
    content: "";
    display: inline-block;
    width: 15px;
    height: 15px;
    -moz-border-radius: 7.5px;
    -webkit-border-radius: 7.5px;
    border-radius: 7.5px;
    background: #ec6ead;
  }
}
.home-project__content {
  max-width: 800px;
  padding: 24px 0 40px;
  @include content;
  @media (max-width: 768px) {
    padding: 24px 0 32px;
  }
}
</style>
