<template>
  <carousel class="carousel" :perPage="1" :navigationEnabled="true">
    <slide v-for="(picture, index) in pictures" :key="index">
      <img
        class="carousel__img"
        :src="require(`@a/img/${picture.src}`)"
        :alt="picture.alt"
      />
    </slide>
  </carousel>
</template>

<script>
import { Carousel, Slide } from "vue-carousel";

export default {
  props: {
    pictures: Array,
  },
  components: {
    Carousel,
    Slide,
  },
};
</script>
ProjectBlock

<style lang="scss">
.carousel {
  padding: 32px 0;
}
.carousel__img {
  width: 83%;
  display: block;
  margin: 0 auto;
  @media (max-width: 768px) {
    width: 100%;
  }
}
.VueCarousel-dot-container,
.VueCarousel-dot[data-v-438fd353] {
  margin-top: 4px !important;
}
.VueCarousel-navigation-button {
  height: 49px;
  width: 49px;
  outline: none;
  @media (max-width: 768px) {
    display: none;
  }
  &.VueCarousel-navigation-prev {
    background: url("./../../assets/img/icons/arrow-right.png");
    padding: 8px;
    position: absolute;
    top: 50%;
    margin-top: -25px;
    left: 40px;
  }

  &.VueCarousel-navigation-next {
    background: url("./../../assets/img/icons/arrow-left.png");
    padding: 8px;
    position: absolute;
    top: 50%;
    margin-top: -25px;
    right: 40px;
  }
}
</style>
