<template>
  <div>
    <div class="project-header">
      <div class="container">
        <div class="project_header__container">
          <div>
            <h1 class="project-header__title">{{ title }}</h1>
            <div class="project-header__flex">
              <div class="project-header__content">
                <div v-html="$t(`${description}`)"></div>
              </div>
              <div class="project-header__information" v-if="url">
                <div>
                  <b>{{ $t('websiteLabel') }} : </b>       
                  <a
                  class="project-header__link"
                  :href="url"
                  target="_blank"
                  rel="noopener noreferrer"
                  v-if="url"
                >
                  {{ website }}</a
                >
                </div>
                <div><b>{{ $t('roleLabel') }} : </b><span>{{ $t('role') }}</span> </div>
                <div><b>{{ $t('yearLabel') }} : </b><span>{{ year }}</span></div>
              </div>
            </div>
          </div>
          <div>
           
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="project-header__details" v-if="problem && goal">
        <div v-if="problem">
          <div class="project-header__details-circle-1"><b class="project-header__subtitle">{{ $t('problem') }}</b></div>
          <div v-html="$t(`${problem}`)"></div>
        </div>
        <img
          src="./../../assets/img/icons/arrow-right-gradient.png"
        />
        <div v-if="goal">
          <div class="project-header__details-circle-2">
            <b class="project-header__subtitle">{{ $t('solution') }}</b>
          </div>
          <div v-html="$t(`${goal}`)"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    name: String,
    description: String,
    problem: String,
    goal: String,
    role: String,
    duration: String,
    url: String,
    website: String,
    year: String
  },
};
</script>

<style lang="scss" scoped>
@import "@a/css/variables.scss";
@import "@a/css/mixins.scss";

.project-header__flex {
  display: flex;
  @media (max-width: 768px) {
    flex-direction: column;
  }
}

.project-header__information {
  font-family: Lato;
  font-size: 1.7rem;
  color: #ffffff;
  line-height: 30px;
  border-left: 1px solid #ffffff;
  padding-left: 40px;
  @media (max-width:768px) {
    padding-left:0;
    padding-top: 24px;
    border: 0;
    font-size: 2rem;
  }
}
.project-header__details-circle-1 {
  width: 135px;
  height: 135px;
  border-radius: 50%;
  background-image: url("./../../assets/img/circle1.png");
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  margin-bottom: 16px;
}

.project-header__details-circle-2 {
  width: 135px;
  height: 135px;
  border-radius: 50%;
  background-image: url("./../../assets/img/circle2.png");
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  margin-bottom: 16px;
}
.project-header {
  background-image: url("./../../assets/img/home/default-background.png");
  background-size: cover;
  padding: 80px 0 72px;
  .project-header__title {
    font-size: 7rem;
    font-family: "Titan One";
    color: $white;
    padding-bottom: 48px;
    @media (max-width: 768px) {
      font-size: 5.5rem;
    }
  }
  .project-header__link {
    color: $white;
    text-decoration: underline;
  }
  .project-header__content {
    @include content;
    color: $white;
    width: 64%;
    padding-right: 32px;
    @media (max-width: 768px) {
      width: 100%;
    }
  }
  .project-header__subtitle {
    color: $grey;
    font-family: Lato;
    font-size: 2.3rem;
    font-weight: 900;
    padding-bottom: 16px;
  }
}
.project-header__details {
  padding: 48px 16px 0 16px;
  display: flex;
  justify-content: center;
  align-items: baseline;
  flex-wrap: wrap;
  gap: 48px;
  
  @media (max-width:768px) {
    flex-direction: column;
    text-align: center;
    align-items: center;
  }
  > img {
    @media (max-width:768px) {
      rotate: 90deg;
      width: 54px;
    }
  }
  div {
   text-align: center;
 }
  > div {
    font-family: Lato;
    font-size: 1.7rem;
    max-width: 32%;
    min-width: 12%;
    b {
      color: $white;
      display: inline-block;
    }
    @media (min-width: 500px) and (max-width: 768px) {
      min-width: 40%;
    }
    @media (max-width: 500px) {
      min-width: 100%;
    }
  }
}
.project_header__container {
  display: flex;
  align-items: center;
  gap: 32px;
  @media (max-width: 992px) {
    flex-direction: column;
  }
}
.project-header__img {
  max-width: 850px;
  width: 100%;
  @media (max-width: 992px) {
    max-width: 550px;
    display: block;
    margin: auto;
  }
}
</style>
