<template>
  <footer>
    <div class="footer__container container">
      <h2 class="footer__title">{{$t('footer.title')}}</h2>
      <div
        class="footer-flex"
        style="display: flex; justify-content: space-between"
      >
        <p class="footer__content">
          {{$t('footer.description')}}
        </p>
        <ul class="footer__list">
          <cookie-law theme="dark-lime"></cookie-law>
          <li>
            <a
              href="https://www.linkedin.com/in/yoanna-juille-11415b124/"
              target="_blank"
              rel="noopener noreferrer"
              ><img src="@a/img/footer/linkedin.png" alt="linkedin icon"
            /></a>
          </li>
          <li><span></span></li>
          <li>
            <a
              href="https://dribbble.com/hiboux-design"
              target="_blank"
              rel="noopener noreferrer"
              ><img src="@a/img/footer/dribbble.png" alt="dribbble icon"
            /></a>
          </li>
          <li><span></span></li>
          <li>
            <a
              href="https://www.instagram.com/hiboux_design/"
              target="_blank"
              rel="noopener noreferrer"
              ><img src="@a/img/footer/instagram.png" alt="github icon"
            /></a>
          </li>
        </ul>
      </div>
    </div>
  </footer>
</template>

<script>
  import CookieLaw from 'vue-cookie-law'
  export default {
    components: { CookieLaw }
  }
</script>

<style lang="scss">
@import "@a/css/variables.scss";
@import "@a/css/mixins.scss";

.Cookie--dark-lime .Cookie__button {
  background: #7452e7!important;
  border-radius: 20px!important;
}
.Cookie>* {
  font-family: Lato!important;
  font-size: 1.5rem!important;
}
.Cookie--dark-lime {
  background-size: cover!important;
  background-image: url("./../assets/img/home/default-background.png")!important;
}
footer {
  padding: 72px 0;
  color: #fff;
  background-size: cover;
  background-image: url("./../assets/img/home/default-background.png");
  @media (max-width: 992px) {
    padding: 48px 0;
  }
  .footer__title {
    font-family: "Titan One";
    font-size: 4rem;
    padding-bottom: 24px;
    width: fit-content;

    &:after {
      content: "";
      display: inline-block;
      width: 15px;
      height: 15px;
      -moz-border-radius: 7.5px;
      -webkit-border-radius: 7.5px;
      border-radius: 7.5px;
      background: #ec6ead;
      margin-left: 4px;
    }
  }
  .footer__content {
    @include content;
    max-width: 550px;
  }
  .footer__list {
    padding: 24px 0 0 0;
    display: flex;
    gap: 24px;
    align-items: center;
    @media (max-width: 768px) {
      gap: 16px;
    }
    li {
      list-style-type: none;
      font-size: 1.7rem;
      a {
        color: $black;
        font-family: Lato;
      }
      span {
        width: 8px;
        height: 8px;
        background-color: #fff;
        border-radius: 50%;
        display: inline-block;
      }
    }
  }
}
.footer-flex {
  align-items: center;
}
@media (max-width: 768px) {
  .footer-flex {
    flex-direction: column;
    align-items: flex-start;
  }
}
</style>
