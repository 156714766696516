var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Header',{attrs:{"title":"Aymeric Aveline","name":"aymeric-aveline","description":"aymericAveline.description","url":"https://aymeric-aveline.com/","year":"2022","website":"aymeric-aveline.com"}}),_c('div',{staticClass:"project-section"},[_c('div',{staticClass:"container"},[_c('ProjectBlock',{attrs:{"title":"titleProject.understandUser"}})],1),_c('div',{staticClass:"container"},[_c('img',{staticStyle:{"width":"100%","padding-top":"32px"},attrs:{"src":require(("./../assets/img/aymeric-aveline/persona-" + (_vm.$i18n.locale) + ".png"))}})])]),_c('div',{staticClass:"project-section--dark"},[_c('div',{staticClass:"container"},[_c('ProjectBlock',{attrs:{"title":"titleProject.prototypeLowFi","description":"aymericAveline.prototypeLowFi.description","picture":{
          src: 'aymeric-aveline/wireframe.png',
          alt: 'Marianne user journey',
        }}})],1)]),_c('div',{staticClass:"project-section"},[_c('div',{staticClass:"container"},[_c('ProjectBlock',{attrs:{"title":"Design system","picture":{
          src: 'aymeric-aveline/design-system.png',
          alt: 'Design system of Aymeric Aveline',
        }}})],1)]),_c('div',{staticClass:"project-section--dark"},[_c('div',{staticClass:"container"},[_c('ProjectBlock',{attrs:{"title":"Mockups"}}),_c('CarouselImg',{attrs:{"pictures":[
        {
          src: 'aymeric-aveline/mockup-desktop-aymeric.png',
          alt: 'Persona Christine',
        },
        {
          src: 'aymeric-aveline/mockup-mobile-aymeric.png',
          alt: 'Persona Christine',
        } ]}}),_c('ProjectBlock',{staticClass:"pt-72px",attrs:{"isSeparatorNeeded":true,"title":"titleProject.whatILearn","description":"aymericAveline.whatILearn.description"}})],1)]),_c('Footer')],1)}
var staticRenderFns = []

export { render, staticRenderFns }