var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Header',{attrs:{"title":"Donquicompte","name":"donquicompte","description":"donquicompte.description","url":"https://donquicompte.com/","problem":"donquicompte.problem","goal":"donquicompte.solution","year":"2022","website":"donquicompte.com"}}),_c('div',{staticClass:"project-section"},[_c('div',{staticClass:"container"},[_c('ProjectBlock',{attrs:{"title":"titleProject.understandUser","description":"donquicompte.understandUser.description"}}),_c('NumericList',{attrs:{"items":[
          'donquicompte.understandUser.firstPoint',
          'donquicompte.understandUser.secondPoint',
          'donquicompte.understandUser.thirdPoint' ]}})],1),_c('div',{staticClass:"container"},[_c('img',{staticStyle:{"width":"100%","padding-top":"32px"},attrs:{"src":require(("./../assets/img/donquicompte/persona-" + (_vm.$i18n.locale) + ".png"))}})])]),_c('div',{staticClass:"project-section--dark"},[_c('div',{staticClass:"container"},[_c('ProjectBlock',{attrs:{"title":"titleProject.prototypeLowFi","description":"donquicompte.prototypeLowFi.description","picture":{
          src: 'donquicompte/wireframe.png',
          alt: 'Marianne user journey',
        }}}),_c('ProjectBlock',{staticClass:"pt-72px",attrs:{"title":"titleProject.usabilityStudy","description":"donquicompte.usabilityStudy.description"}}),_c('NumericList',{attrs:{"items":[
          'donquicompte.usabilityStudy.firstPoint',
          'donquicompte.usabilityStudy.secondPoint',
          'donquicompte.usabilityStudy.thirdPoint' ]}})],1)]),_c('div',{staticClass:"project-section"},[_c('div',{staticClass:"container"},[_c('ProjectBlock',{attrs:{"title":"Design system","picture":{
          src: 'donquicompte/design-system.png',
          alt: 'Design system of Donquicompte',
        }}})],1)]),_c('div',{staticClass:"project-section--dark"},[_c('div',{staticClass:"container"},[_c('ProjectBlock',{attrs:{"title":"Mockups","description":"donquicompte.mockups.description"}}),_c('CarouselImg',{attrs:{"pictures":[
          {
            src: 'donquicompte/mockup-search.png',
            alt: 'Mockup home',
          },
          {
            src: 'donquicompte/mockup-home.png',
            alt: 'Mockup Login',
          } ]}}),_c('div',{staticClass:"text-center"},[_c('a',{staticClass:"project-section__button--light",attrs:{"href":"https://www.figma.com/proto/VdUdi06tlDymmMG63PIwM3/Donquicompte-v3?page-id=164%3A2522&node-id=207%3A4093&viewport=244%2C48%2C0.39&scaling=scale-down&starting-point-node-id=207%3A4093&show-proto-sidebar=1","target":"blank"}},[_vm._v(_vm._s(_vm.$t('donquicompte.mockups.button')))])]),_c('ProjectBlock',{staticClass:"pt-72px",attrs:{"title":"titleProject.accesibility"}}),_c('NumericList',{attrs:{"items":[
          'donquicompte.accessibility.firstPoint',
          'donquicompte.accessibility.secondPoint' ]}}),_c('ProjectBlock',{staticClass:"pt-72px",attrs:{"isSeparatorNeeded":true,"title":"titleProject.whatILearn","description":"donquicompte.whatILearn.description"}})],1)]),_c('Footer')],1)}
var staticRenderFns = []

export { render, staticRenderFns }