<template>
  <div>
    <Header
      title="Donquicompte"
      name="donquicompte"
      description="donquicompte.description"
      url="https://donquicompte.com/"
      problem="donquicompte.problem"
      goal="donquicompte.solution"
      year="2022"
      website="donquicompte.com"
    />
    <!-- 
      role="<div>UX/UI designer</div><div>Dévelopeuse Front-end</div><div>Responsive website</div>"
      duration="<div>Octobre 2021 -</div> <div>Janvier 2022</div>"-->
    <div class="project-section">
      <div class="container">
        <ProjectBlock
          title="titleProject.understandUser"
          description="donquicompte.understandUser.description"
        />
        <NumericList
          :items="[
            'donquicompte.understandUser.firstPoint',
            'donquicompte.understandUser.secondPoint',
            'donquicompte.understandUser.thirdPoint',
          ]"
        />
      </div>

      <div class="container">
        <img :src="require(`./../assets/img/donquicompte/persona-${$i18n.locale}.png`)" style="width:100%;padding-top:32px;"/>
      </div>
    </div>

    <div class="project-section--dark">
      <div class="container">
        <ProjectBlock
          title="titleProject.prototypeLowFi"
          description="donquicompte.prototypeLowFi.description"
          :picture="{
            src: 'donquicompte/wireframe.png',
            alt: 'Marianne user journey',
          }"
        />

        <ProjectBlock
          class="pt-72px"
          title="titleProject.usabilityStudy"
          description="donquicompte.usabilityStudy.description"
        />

        <NumericList
          :items="[
            'donquicompte.usabilityStudy.firstPoint',
            'donquicompte.usabilityStudy.secondPoint',
            'donquicompte.usabilityStudy.thirdPoint',
          ]"
        />
      </div>
    </div>

    <div class="project-section">
      <div class="container">
        <ProjectBlock
          title="Design system"
          :picture="{
            src: 'donquicompte/design-system.png',
            alt: 'Design system of Donquicompte',
          }"
        />
      </div>
    </div>
    <div class="project-section--dark">
      <div class="container">
        <ProjectBlock
          title="Mockups"
          description="donquicompte.mockups.description"
        />

        <CarouselImg
          :pictures="[
            {
              src: 'donquicompte/mockup-search.png',
              alt: 'Mockup home',
            },
            {
              src: 'donquicompte/mockup-home.png',
              alt: 'Mockup Login',
            },
          ]"
        />
        <div class="text-center">
          <a
            class="project-section__button--light"
            href="https://www.figma.com/proto/VdUdi06tlDymmMG63PIwM3/Donquicompte-v3?page-id=164%3A2522&node-id=207%3A4093&viewport=244%2C48%2C0.39&scaling=scale-down&starting-point-node-id=207%3A4093&show-proto-sidebar=1"
            target="blank"
            >{{$t('donquicompte.mockups.button')}}</a
          >
        </div>

        <ProjectBlock class="pt-72px" title="titleProject.accesibility" />
        <NumericList
          :items="[
            'donquicompte.accessibility.firstPoint',
            'donquicompte.accessibility.secondPoint',
          ]"
        />
        <ProjectBlock
          :isSeparatorNeeded="true"
          class="pt-72px"
          title="titleProject.whatILearn"
          description="donquicompte.whatILearn.description"
        />
      </div>
    </div>
    <Footer />
  </div>
</template>

<style lang="scss">
@import "@a/css/variables.scss";
@import "@a/css/mixins.scss";

.project-section--dark {
  background: $black;
  color: $white;
}
.project-section,
.project-section--dark {
  padding: 72px 0;
  .project-section__title {
    font-size: 4rem;
    font-weight: 900;
    padding-bottom: 40px;
    font-family: "Titan One";
  }
  .project-section__content {
    @include content;
  }
  .project-section__img {
    display: block;
    margin: 0 auto;
    padding-top: 32px;
    width: 83%;
    @media (max-width: 768px) {
      width: 100%;
    }
  }
}
.project-section__button--light {
  border-radius: 26px;
  padding: 8px 48px;
  color: #ffffff;
  transition: all 0.5s;
  display: inline-block;
  font-family: Lato;
  font-size: 1.7rem;
  background: #7452e7;
  border: 0;
  box-shadow: 5px 5px 15px rgb(69 92 215 / 40%);
  &:hover {
    background-image: linear-gradient(
      270deg,
      #ec6ead 12.36%,
      #5c4cf2 61.26%,
      #4568dc 96.2%
    );
    color: #ffffff;
  }
}
</style>

<script>
import Header from "@c/project/Header.vue";
import CarouselImg from "@c/project/CarouselImg.vue";
import NumericList from "@c/project/NumericList.vue";
import ProjectBlock from "@c/project/ProjectBlock.vue";
import Footer from "@c/Footer.vue";

export default {
  
  components: {
    Header,
    CarouselImg,
    NumericList,
    ProjectBlock,
    Footer,
  },
  mounted() {
    this.$ga.page("/donquicompte");
  },
};
</script>
