<template>
  <div class="project-section-list">
    <div v-for="(item, index) in items" :key="index">
      <div class="project-section-list__number">{{ index + 1 }}</div>
      <p class="project-section-list__content" v-html="$t(`${item}`)"></p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    items: Array,
  },
};
</script>

<style lang="scss">
@import "@a/css/variables.scss";
@import "@a/css/mixins.scss";

.project-section--dark {

  .project-section-list__content {
    @include content;
    font-weight: 500;
    color: $white;
  }
}
.project-section-list {
  display: flex;
  padding-top: 48px;
  justify-content: center;
  gap: 48px;
  text-align: center;
  align-items: flex-start;
  @media (max-width: 500px) {
    flex-direction: column;
    align-items: center;
  }
  > div {
    max-width: 25%;
    @media (max-width: 500px) {
      max-width: 90%;
    }
  }
}
.project-section-list__number {
  border: 1px solid $white;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  margin: 0 auto;
  background: url("./../../assets/img/circle.png");
  color: $white;
  font-family: Lato;
  font-size: 25px;
  font-weight: 900;
  text-align: center;
  line-height: 50px;
  margin-bottom: 24px;
  background-size: cover;
}
.project-section-list__content {
  color: #000 !important;
  @include content;
  font-weight: 500;
}
</style>
