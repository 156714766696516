var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Header',{attrs:{"title":"medici.tv","name":"medici-tv","description":"medici.description","url":"https://www.medici.tv/","year":"2022","website":"medici.tv","problem":"Faire la promotion du Jazz sans faire d'ombre aux catégories existantes dédiées à la musique classique.","goal":"Créer une landing page mélant de manière intelligente le Jazz et la musique classique"}}),_c('div',{staticClass:"project-section"},[_c('div',{staticClass:"container"},[_c('ProjectBlock',{attrs:{"title":"titleProject.understandProject","description":"medici.understandProject.description"}})],1)]),_c('div',{staticClass:"project-section--dark"},[_c('div',{staticClass:"container"},[_c('ProjectBlock',{attrs:{"title":"titleProject.understandUser"}}),_c('img',{staticStyle:{"width":"100%"},attrs:{"src":require(("./../assets/img/medici-tv/persona-" + (_vm.$i18n.locale) + ".png"))}})],1)]),_c('div',{staticClass:"project-section"},[_c('div',{staticClass:"container"},[_c('ProjectBlock',{attrs:{"title":"Design system","picture":{
          src: 'medici-tv/design-system.png',
          alt: 'Design system of medici.tv',
        }}})],1)]),_c('div',{staticClass:"project-section--dark"},[_c('div',{staticClass:"container"},[_c('ProjectBlock',{attrs:{"title":"Mockups"}}),_c('CarouselImg',{attrs:{"pictures":[
        {
          src: 'medici-tv/mockup-desktop-medici.png',
          alt: 'Persona Christine',
        },
        {
          src: 'medici-tv/mockup-mobile-medici.png',
          alt: 'Persona Christine',
        } ]}})],1)]),_c('div',{staticClass:"project-section--dark"},[_c('div',{staticClass:"container"},[_c('ProjectBlock',{attrs:{"title":"titleProject.accesibility"}}),_c('NumericList',{attrs:{"items":[
          'Utilisation d\'icônes dans la partie Pourquoi choisir medici.tv? pour une meilleure compréhension des différents avantages.',
          'Ajout de textes alt aux aux images et augmentation de la taille de la police pour les personnes malvoyantes. ' ]}}),_c('ProjectBlock',{staticClass:"pt-72px",attrs:{"isSeparatorNeeded":true,"title":"titleProject.whatILearn","description":"medici.whatILearn.description"}})],1)]),_c('Footer')],1)}
var staticRenderFns = []

export { render, staticRenderFns }