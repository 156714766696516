<template>
  <div>
    <div v-if="isSeparatorNeeded" class="project-section__separator"></div>
    <h2 v-if="title" class="project-section__title" v-html="$t(`${title}`)"></h2>
    <div
      v-if="description"
      class="project-section__content"
      v-html="$t(`${description}`)"
    ></div>
    <img
      v-if="picture"
      class="project-section__img"
      :src="require(`@a/img/${picture.src}`)"
      :alt="picture.alt"
    />
    <div v-if="link" class="text-center" style="padding-top: 40px">
      <a class="project-section__button--light" :href="link" target="blank"
        >Voir le prototype</a
      >
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    description: String,
    picture: Object,
    link: String,
    isSeparatorNeeded: Boolean,
  },
};
</script>
<style>
.project-section__separator {
  background: linear-gradient(270deg, #ec6ead 12.36%, #5c4cf2 61.26%, #4568dc 96.2%);
  height: 1px;
  margin-bottom: 72px;
}
</style>
