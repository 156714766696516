<template>
  <div> 
    <div class="home">
      <div>
        <h1 class="home__title"><span>Hibo</span>ux Design</h1>
        <p class="home__content">
          {{ $t("home.presentation") }}
        </p>
        <a href="#projects">
          <img
            class="home__arrow"
            src="@a/img/home/arrow-down.png"
            alt="arrow down"
          />
        </a>
      </div>
      <video
        autoplay
        playsinline
        loop
        muted
        poster="./../assets/img/home/owl.png"
        src="./../assets/img/owl.webm"
        class="home__animation"
      ></video>
      <a href="#projects">
        <img
          class="home__arrow-responsive"
          src="@a/img/home/arrow-down.png"
          alt="arrow down"
        />
      </a>
    </div>
    <section id="projects">
      <HomeProject
        img="donquicompte-thumbnail1.png"
        title="Donquicompte"
        details="home.projects.categories"
        description="home.projects.donquicompte.description"
        slug="donquicompte"
        :separator="true"
        class="donquicompte"
      />

      <HomeProject
        :isDispositionDifferent="true"
        img="aymeric-aveline-thumbnail1.png"
        title="Aymeric Aveline"
        details="home.projects.categories"
        description="home.projects.aymericAveline.description"
        slug="aymeric-aveline"
        :separator="true"
        class="aymeric-aveline"
      />
      <HomeProject
        img="medici-thumbnail1.png"
        title="medici.tv"
        details="home.projects.categories"
        description="home.projects.medici.description"
        slug="medici-tv"
        :separator="false"
        class="medici-tv"
      />
    </section>

    <section class="illustrations-section home-project">
      <div class="container">
        <div class="flex">
          <div class="container-illustrations">
            <h2 style="width: fit-content" class="home-project__title">
              {{$t('home.illustrations.title')}}
            </h2>
            <p class="home-project__content" style="max-width: 400px">
              {{$t('home.illustrations.description')}}
            </p>
            <a class="home-project__button" href="https://www.instagram.com/hiboux_design/" target="_blank" rel="noopener noreferrer">{{$t('home.illustrations.button')}}</a>
           <!-- <router-link to="/3d-work" class="home-project__button"> {{$t('home.illustrations.button')}}</router-link>-->
          </div>
          <img src="./../assets/img/home/3D-projects.png" alt="3d projects"/>
        </div>
      </div>
    </section>

    <!--<About />-->
    <Footer />
  </div>
</template>

<script>
import HomeProject from "@c/HomeProject.vue";
import Footer from "@c/Footer.vue";

export default {
  components: {
    HomeProject,
    Footer,
  },
  mounted() {
    this.$ga.page("/");
  },
};
</script>

<style lang="scss">
@import "@a/css/variables.scss";

html {
  font-size: 62.5%;
  scroll-behavior: smooth;
}

@media (min-width: 992px) and (max-width: 1200px) {
  html {
    font-size: 50%;
  }
}
@media (max-width: 992px) {
  html {
    font-size: 47%;
  }
}
.container-illustrations {
  display: flex; 
  flex-direction: column;
  @media (max-width: 1024px) {
    display: block;
  }
}
.illustrations-section {
  background: url("./../assets/img/home/background-light.png") no-repeat;
  position: relative;
  .flex {
    display: flex;
    gap: 48px;
    justify-content: space-between;
    align-content: center;
    flex-wrap: wrap;
    padding: 0 8px;
    @media (max-width: 992px) {
      gap: 8px;
    }
  }
  img {
    width: 60%;
  }
}
.home__animation {
  width: 60%;
  filter: contrast(1.2);
  right: 0;
  position: absolute;
  @media (max-width: 768px) {
    position: relative;
    width: 100%;
  }
}
.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 24px;
  @media (max-width: 768px) {
    padding: 0 16px;
  }
}
.home {
  background: url("./../assets/img/home/default-background.png");
  height: 100vh;
  background-size: cover;
  background-position: 50% 50%;
  display: flex;
  align-items: center;
  @media (max-width: 992px) {
    height: 100%;
  }
  @media (max-width: 768px) {
    padding: 24px 0;
    width: 100%;
    min-height: auto;
    height: 100%;
    min-height: 100vh;
    justify-content: center;
    flex-direction: column;
    position: relative !important;
  }
  > div {
    padding: 48px;
    max-width: 630px;
    z-index: 10;
    @media (max-width: 768px) {
      padding: 24px;
    }
  }
  .home__title {
    color: $white;
    font-family: "Titan One";
    font-size: 14rem;
    font-weight: 900;
    line-height: 132px;
    span {
      background: linear-gradient(
        270deg,
        #ec6ead 12.36%,
        #5c4cf2 61.26%,
        #4568dc 96.2%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }
    @media (min-width: 1500px) {
      font-size: 18rem;
      line-height: 160px;
    }
    @media (max-width: 768px) {
      font-size: 10rem;
      line-height: 70px;
    }
  }
  .home__content {
    color: $white;
    font-family: Lato;
    padding: 48px 0 24px;
    font-size: 1.7rem;
    @media (max-width: 992px) {
      font-size: 2rem;
    }
  }
  .home__arrow {
    display: block;
    margin: 0 auto;
    animation: 1.5s linear 0s infinite moveArrow;
    @media (max-width: 768px) {
      display: none;
    }
  }
  .home__arrow-responsive {
    display: none;
    margin: 0 auto;
    height: 40px;
    animation: 1.5s linear 0s infinite moveArrow;
    @media (max-width: 768px) {
      display: block;
    }
  }
  @keyframes moveArrow {
    0% {
      -moz-transform: translateY(0);
      transform: translateY(0);
      opacity: 1;
    }
    100% {
      opacity: 0;
      -moz-transform: translateY(16px);
      transform: translateY(16px);
    }
  }
}
@media (max-width: 1024px) {
  .illustrations-section img {
    width: 100%;
    padding-top: 24px;
  }
}
.home .medici-tv img {
  max-height: 400px;
}
</style>
