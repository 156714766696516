<template>
  <div>
    <Header
      title="Aymeric Aveline"
      name="aymeric-aveline"
      description="aymericAveline.description"
      url="https://aymeric-aveline.com/"
      year="2022"
      website="aymeric-aveline.com"
    />
    <!--Créer une landing page afin de faire la promotion de la nouvelle catégorie Jazz."-->
    <!--Créer un blog avec une identité forte et comme contrainte, la couleur bleu, qui est très utilisée dans le milieu des nouvelles technologies. Chaque article devait être illustré par une illustration 3D pour donner une dimension plus futuriste au site.-->

    <div class="project-section">
      <div class="container">
        <ProjectBlock
          title="titleProject.understandUser" 
        />
      </div>

      <div class="container">
        <img :src="require(`./../assets/img/aymeric-aveline/persona-${$i18n.locale}.png`)" style="width:100%;padding-top:32px;"/>
      </div>
    </div>

    <div class="project-section--dark">
      <div class="container">
        <ProjectBlock
          title="titleProject.prototypeLowFi"
          description="aymericAveline.prototypeLowFi.description"
          :picture="{
            src: 'aymeric-aveline/wireframe.png',
            alt: 'Marianne user journey',
          }"
        />
      </div>
    </div>

    <div class="project-section">
      <div class="container">
        <ProjectBlock
          title="Design system"
          :picture="{
            src: 'aymeric-aveline/design-system.png',
            alt: 'Design system of Aymeric Aveline',
          }"
        />
      </div>
    </div>

    <div class="project-section--dark">
      <div class="container">
        <ProjectBlock
          title="Mockups"
        />
        <CarouselImg
        :pictures="[
          {
            src: 'aymeric-aveline/mockup-desktop-aymeric.png',
            alt: 'Persona Christine',
          },
          {
            src: 'aymeric-aveline/mockup-mobile-aymeric.png',
            alt: 'Persona Christine',
          },
        ]"
      />
      <ProjectBlock
        :isSeparatorNeeded="true"
        class="pt-72px"
        title="titleProject.whatILearn"
        description="aymericAveline.whatILearn.description"
      />

      </div>
    </div>
    <Footer />
  </div>
</template>

<style lang="scss">
@import "@a/css/variables.scss";
@import "@a/css/mixins.scss";
.project-section__title {
  font-size: 4rem;
  font-weight: 900;
  padding-bottom: 40px;
  font-family: "Titan One";
  position: relative;
  display: inline-block;
  z-index: 10;
  &:before {
    background-position-x: 65%;
    z-index: 10;
    content: "";
    width: calc(100% - 24px);
    height: 18px;
    position: absolute;
    bottom: 34px;
    background-size: 200% 100%;
    transition: background-position 0.7s;
    z-index: -1;
    background-image: linear-gradient(
      270deg,
      #ec6ead 12.36%,
      #5c4cf2 61.26%,
      #4568dc 96.2%
    );
  }
}
.project-section--dark {
  background: #f0f1ff;
  color: #000;
}
.project-section,
.project-section--dark {
  padding: 72px 0;
  .project-section__title {
    font-size: 4rem;
    font-weight: 900;
    padding-bottom: 40px;
    font-family: "Titan One";
  }
  .project-section__content {
    @include content;
  }
  .project-section__img {
    display: block;
    margin: 0 auto;
    padding-top: 32px;
    width: 83%;
    @media (max-width: 768px) {
      width: 100%;
    }
  }
}
</style>

<script>
import Header from "@c/project/Header.vue";
import ProjectBlock from "@c/project/ProjectBlock.vue";
import Footer from "@c/Footer.vue";
import CarouselImg from "@c/project/CarouselImg.vue";

export default {
  components: {
    CarouselImg,
    Header,
    ProjectBlock,
    Footer,
  },
  mounted() {
    this.$ga.page("/bicycle-app");
  },
};
</script>
