<template>
  <div>
    <Header
      title="medici.tv"
      name="medici-tv"
      description="medici.description"
      url="https://www.medici.tv/"
      year="2022"
      website="medici.tv"
      problem="Faire la promotion du Jazz sans faire d'ombre aux catégories existantes dédiées à la musique classique."
      goal="Créer une landing page mélant de manière intelligente le Jazz et la musique classique"
    />
    <!--Créer une landing page afin de faire la promotion de la nouvelle catégorie Jazz."-->
    <div class="project-section">
      <div class="container">
        <ProjectBlock
          title="titleProject.understandProject"
          description="medici.understandProject.description"
        />
      </div>
    </div>

    <div class="project-section--dark">
      <div class="container">
        <ProjectBlock
          title="titleProject.understandUser"
        />
        <img :src="require(`./../assets/img/medici-tv/persona-${$i18n.locale}.png`)" style="width:100%"/>
      </div>
    </div>

    <div class="project-section">
      <div class="container">
        <ProjectBlock
          title="Design system"
          :picture="{
            src: 'medici-tv/design-system.png',
            alt: 'Design system of medici.tv',
          }"
        />
      </div>
    </div>

    <div class="project-section--dark">
      <div class="container">
        <ProjectBlock
          title="Mockups"
        />
        <CarouselImg
        :pictures="[
          {
            src: 'medici-tv/mockup-desktop-medici.png',
            alt: 'Persona Christine',
          },
          {
            src: 'medici-tv/mockup-mobile-medici.png',
            alt: 'Persona Christine',
          },
        ]"
      />

      </div>
    </div>

    <div class="project-section--dark">
      <div class="container">
        <ProjectBlock title="titleProject.accesibility" />
        <NumericList
          :items="[
            'Utilisation d\'icônes dans la partie Pourquoi choisir medici.tv? pour une meilleure compréhension des différents avantages.',
            'Ajout de textes alt aux aux images et augmentation de la taille de la police pour les personnes malvoyantes. ',
          ]"
        />
        <ProjectBlock
          :isSeparatorNeeded="true"
          class="pt-72px"
          title="titleProject.whatILearn"
          description="medici.whatILearn.description"
        />
        </div>
    </div>
    <Footer />
  </div>
</template>

<style lang="scss">
@import "@a/css/variables.scss";
@import "@a/css/mixins.scss";

.project-section--dark {
  background: #f0f1ff;
  color: #000;
}
.project-section,
.project-section--dark {
  padding: 72px 0;
  .project-section__title {
    font-size: 4rem;
    font-weight: 900;
    padding-bottom: 40px;
    font-family: "Titan One";
  }
  .project-section__content {
    @include content;
  }
  .project-section__img {
    display: block;
    margin: 0 auto;
    padding-top: 32px;
    width: 83%;
    @media (max-width: 768px) {
      width: 100%;
    }
  }
}
</style>

<script>
import Header from "@c/project/Header.vue";
import ProjectBlock from "@c/project/ProjectBlock.vue";
import NumericList from "@c/project/NumericList.vue";
import Footer from "@c/Footer.vue";
import CarouselImg from "@c/project/CarouselImg.vue";

export default {

  components: {
    Header,
    ProjectBlock,
    NumericList,
    Footer,
    CarouselImg
  },
  mounted() {
    this.$ga.page("/medici-tv");
  },
};
</script>
