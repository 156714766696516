<template>
  <div> 
    <div class="about">
      <div class="about__flex">
        <div>
          <h1 class="about__title" v-html="$t('about.title')"></h1>
        </div>
        <img src="./../assets/img/about/yoanna.png" alt="avatar Yoanna" class="avatar"/>
      </div>
    </div>
    <section style="padding: 48px 0;">
      <div class="container">
        <div class="about__content" v-html="$t('about.description')">
        </div>
      </div>
    </section>
    <Footer />
  </div>
</template>

<script>
import Footer from "@c/Footer.vue";

export default {
  components: {
    Footer,
  },
  mounted() {
    this.$ga.page("/");
  },
};
</script>

<style lang="scss">
@import "@a/css/variables.scss";
.avatar {
  width: auto;
  height: 100%;
  position: absolute;
  right: 0;
  @media (max-width: 768px) {
    height: auto;
    width: 100%;
    position: relative;
  }
}
.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 24px;
  @media (max-width: 768px) {
    padding: 0 16px;
  }
}
.about__flex {
  background-position: 50% 50%;
  display: flex;
  align-items: center;
  max-width: 1200px;
  width: 80%;
  height: 100vh;
  margin: 0 auto;
  position: relative;
    @media (max-width: 768px) {
      padding: 24px 0 0 0;
      width: 100%;
      height: 100%;
      justify-content: center;
      flex-direction: column;
      position: relative !important;
    }   
}
.about {
  background: url("./../assets/img/home/default-background.png");
  height: 100vh;
  background-size: cover;
  @media (max-width:992px) {
    height: 100%; 
  }

  .about__title {
    color: $white;
    font-family: "Titan One";
    font-size: 14rem;
    font-weight: 900;
    line-height: 132px;
    span {
      display:block;
      background: linear-gradient(
        270deg,
        #ec6ead 12.36%,
        #5c4cf2 61.26%,
        #4568dc 96.2%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }
    @media (min-width: 1500px) {
      font-size: 18rem;
      line-height: 160px;
    }
    @media (max-width: 768px) {
      font-size: 10rem;
      line-height: 70px;
    }
  }

}

.about__content {
    font-family: Lato;
    padding: 48px 0 24px;
    font-size: 1.7rem;
    p {
      padding-bottom: 24px;
      line-height: 175%;
    }
    @media (max-width: 992px) {
      font-size: 2rem;
    }
  } 
</style>
