<template>
  <div id="app">
    <div class="container" style="position:relative;">
      <div class="container-navigation">
      <div class="container-navigation__flex">
        <div class="navigation" ref="navigation">
          <router-link to="/">
            <img
              class="navigation__img"
              src="@a/img/logo-hiboux-design.svg"
              alt="logo Hiboux Design"
            />
          </router-link>
        </div>
        <div class="navigation__right">
          <router-link to="/about">{{$t('nav.about')}}</router-link>
          <div class="dropdown" ref="dropdown">
            <div @click="toggle = !toggle" class="dropdown__button"><span>{{$t('nav.projects')}}</span> <img src="./assets/img/icons/arrow-down.svg"/></div>
            <div v-if="toggle" class="dropdown__content">
              <ul @click="toggle = !toggle">
                <li><router-link  to="/donquicompte">Donquicompte</router-link></li>
                <li><router-link  to="/aymeric-aveline">Aymeric Aveline</router-link></li>
                <li><router-link to="medici-tv">medici.tv</router-link></li>
              </ul>
            </div>
          </div>
          <div class="locale-changer">
            <div>
              <span :class="{active : langs[0] === $i18n.locale}"  @click="changeLocale(langs[0])">{{langs[0]}}</span>
              <span> / </span><span :class="{active : langs[1] === $i18n.locale}"  @click="changeLocale(langs[1])">{{langs[1]}}</span>
            </div>
          </div>
        </div>

        <!-- Navigation mobile -->
        <div class="navigation-mobile">
          <img @click="toggleResponsive = !toggleResponsive" src="./assets/img/icons/burger-menu.svg" alt="burger-menu" class="navigation-mobile__burger"/>
          <div v-if="toggleResponsive" class="navigation-responsive__container">
            <router-link @click.native="toggleResponsive=false" to="/about">{{$t('nav.about')}}</router-link> 
            <div class="dropdown" ref="dropdown">
              <div @click="toggle = !toggle" class="dropdown__button"><span>{{$t('nav.projects')}}</span> <img src="./assets/img/icons/arrow-down.svg"/></div>
              <div v-if="toggle" class="dropdown__content">
                <ul @click="toggle = !toggle">
                  <li><router-link  @click.native="toggleResponsive=false" to="/donquicompte">Donquicompte</router-link></li>
                  <li><router-link  @click.native="toggleResponsive=false" to="/aymeric-aveline">Aymeric Aveline</router-link></li>
                  <li><router-link @click.native="toggleResponsive=false" to="medici-tv">medici.tv</router-link></li>
                </ul>
              </div>
            </div>
            <div class="locale-changer">
              <div>
                <span :class="{active : langs[0] === $i18n.locale}"  @click="changeLocale(langs[0])">{{langs[0]}}</span>
                <span> / </span><span :class="{active : langs[1] === $i18n.locale}"  @click="changeLocale(langs[1])">{{langs[1]}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      toggleResponsive: false,
      toggle: false,
      cursorCircle: this.$refs.cursorCircle,
      langs: ['fr', 'en'],
    };
  },
  methods : {
    setIsToggle(e) {
       this.toggle = this.$refs.dropdown.contains(e.target);
    },
    changeLocale(lang) {
      this.$i18n.locale = lang;
    }
  },
  mounted() {
    document.addEventListener("click", this.setIsToggle);
  }
};
</script>

<style lang="scss" scoped>
@import "@a/css/variables.scss";
.navigation-mobile {
  display: none;
  @media (max-width:992px) {
    display: block;
  }
  .navigation-mobile__burger {
    width: 40px;
    position: absolute;
    right: 16px;
    cursor: pointer;
    z-index: 10;
  }
  .navigation-responsive__container {
    flex-direction: column;
    gap: 32px;
    justify-content: center;
    align-items: center;
    display: flex;
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #f0f1ff;
    a, span {
      color: #000000;
    }
    .dropdown__button {
      width: 160px;
      margin: 0 auto;
      text-align: center;
      display: block;
    }
    .dropdown ul {
      position: relative;
      background: none;
      text-align: center;
      opacity: 1;
      transform: translateY(0);
      animation: 1s;
      &:before {
        height: 1px;
      }
    }
  }
}
.navigation__right {
  display: flex;
  gap: 32px;
  align-items: center;
  @media (max-width: 992px) {
    display: none;
  }
}
.dropdown__button{
  font-size: 20px;
  font-family: Lato;
  color: #ffffff;
  display: flex;
  cursor: pointer;
}    
.dropdown {
  ul {
    position: absolute;
    background: #ffffff;
    margin-top: 16px;
    padding: 0;
    border-radius: 0 0 16px 16px;
    padding-top: 5px;
    &::before {
      content: "";
        height: 5px;
        width: 100%;
        background-image: linear-gradient(270deg, #ec6ead 12.36%, #5c4cf2 61.26%, #4568dc 96.2%);
        display: block;
        position: absolute;
        top: 0;
        left: 0;
    }
    li {
      list-style-type: none;
      padding: 12px 16px;
      &:hover {
        background-color: #f0f1ff;
      }
      a {
        font-size: 18px;
        color: #000000;
        font-family: Lato;
      }
    }
  }
}
a {
  font-size:20px;
  font-family: Lato;
  color: #ffffff;
}
.locale-changer {
  font-size:20px;
  font-family: Lato;

  span {
    cursor: pointer;
    text-transform: capitalize;
    color: #ffffff;
    &.active {
      color: #EC6EAD;
      font-weight: bold;
      text-decoration:underline;
    }
  }
}

.container-navigation {
  position: absolute;
  top: 0;
  width: 100%;
  left:0;
  padding:16px;
  z-index: 100;
}
.container-navigation__flex {
  display: flex; 
  justify-content: space-between;
}
.navigation {
  height: 50px;
  
  &.sticky {
    background: $black;
    .navigation__text {
      opacity: 1;
    }
  }
  a {
    display: flex;
    align-items: center;
  }
}
.navigation__img {
  height: 48px;
}

</style>
