import Vue from "vue";
import App from "./App.vue";
import VueRouter from "vue-router";
import VueCarousel from "vue-carousel";
import VueAnalytics from "vue-analytics";
import VueI18n from 'vue-i18n'
import About from "./views/About.vue";
import AymericAveline from "./views/AymericAveline.vue";
import Illustration from "./views/Illustration.vue";
import Donquicompte from "./views/Donquicompte.vue";
import MediciTv from "./views/MediciTv.vue";
import Home from "./views/Home.vue";
import "./assets/css/global.scss";
import translations from "./translations.json";

Vue.config.productionTip = false;

Vue.use(VueRouter);
Vue.use(VueCarousel);
Vue.use(VueI18n)

Vue.use(VueAnalytics, {
  id: "UA-40550598-1",
});

const messages = translations;

const i18n = new VueI18n({
  locale: 'fr', // set locale
  messages, // set locale messages
})
new Vue({
  i18n,
  router: new VueRouter({
    scrollBehavior() {
      return { x: 0, y: 0, behavior: 'auto'};
    },
    mode: "history",
    routes: [
      {
        path: "/",
        component: Home,
        
      },
      {
        path: "/aymeric-aveline",
        component: AymericAveline,
      },
      {
        path: "/about",
        component: About,
      },
      {
        path: "/3D-work",
        component: Illustration,
      },
      {
        path: "/donquicompte",
        component: Donquicompte,
      },
      {
        path: "/medici-tv",
        component: MediciTv,
      },
    ],
  }),
  render: (h) => h(App),
}).$mount("#app");




