<template>
  <div>
    <Header
      title="3D Illustrations"
      name="3D-work"
      description="illustrations.description"
    />

    <div class="project-section">
      <div class="container">
        <ProjectBlock
          title="Architecture" 
        />
      </div>

      <div class="container">
        <div class="grid-2columns">
          <img :src="require(`./../assets/img/3D/architecture3.webp`)"/>
          <img :src="require(`./../assets/img/3D/architecture4.png`)"/>
          <img :src="require(`./../assets/img/3D/architecture1.webp`)"/>
          <img :src="require(`./../assets/img/3D/architecture2.webp`)"/>
          <img :src="require(`./../assets/img/3D/architecture5.webp`)"/>
          <img :src="require(`./../assets/img/3D/architecture6.webp`)"/>
        </div>
      </div>
    </div>

    
    <div class="project-section--dark">
      <div class="container">
        <ProjectBlock
          title="Miscellaneous" 
        />
      </div>

      <div class="container">
        <div class="grid-3columns">
          <img :src="require(`./../assets/img/3D/miscellaneous1.png`)"/>
          <img :src="require(`./../assets/img/3D/miscellaneous2.png`)"/>
          <img :src="require(`./../assets/img/3D/miscellaneous3.png`)"/>
        </div>
      </div>
    </div>

    <!--<div class="project-section--dark">
      <div class="container">
        <ProjectBlock
          title="Miscellaneous" 
        />
      </div>

      <div class="container">
        <div class="grid-1column">
          <img :src="require(`./../assets/img/3D/icons.webp`)"/>
        </div>
      </div>
    </div>-->




    <Footer />
  </div>
</template>

<style lang="scss">
@import "@a/css/variables.scss";
@import "@a/css/mixins.scss";

.grid-2columns {
  display: grid;
  grid-gap: 24px;
  grid-template-columns: 1fr 1fr;
  @media (max-width: 550px) {
    grid-template-columns: 1fr!important;
  }
  img {
    width: 100%;
  }
}
.grid-3columns {
  display: grid;
  grid-gap: 24px;
  grid-template-columns: 1fr 1fr 1fr;
  @media (max-width: 550px) {
    grid-template-columns: 1fr!important;
  }
  img {
    width: 100%;
  }
}
.grid-1column {
  display: grid;
  grid-gap: 24px;
  grid-template-columns: 1fr;
  img {
    width: 100%;
  }
}

.project-section__title {
  font-size: 4rem;
  font-weight: 900;
  padding-bottom: 40px;
  font-family: "Titan One";
  position: relative;
  display: inline-block;
  z-index: 10;
  &:before {
    background-position-x: 65%;
    z-index: 10;
    content: "";
    width: calc(100% - 24px);
    height: 18px;
    position: absolute;
    bottom: 34px;
    background-size: 200% 100%;
    transition: background-position 0.7s;
    z-index: -1;
    background-image: linear-gradient(
      270deg,
      #ec6ead 12.36%,
      #5c4cf2 61.26%,
      #4568dc 96.2%
    );
  }
}
.project-section--dark {
  background: #f0f1ff;
  color: #000;
}
.project-section,
.project-section--dark {
  padding: 72px 0;
  .project-section__title {
    font-size: 4rem;
    font-weight: 900;
    padding-bottom: 40px;
    font-family: "Titan One";
  }
  .project-section__content {
    @include content;
  }
  .project-section__img {
    display: block;
    margin: 0 auto;
    padding-top: 32px;
    width: 83%;
    @media (max-width: 768px) {
      width: 100%;
    }
  }
}
</style>

<script>
import Header from "@c/project/Header.vue";
import ProjectBlock from "@c/project/ProjectBlock.vue";
import Footer from "@c/Footer.vue";

export default {
  components: {
    Header,
    ProjectBlock,
    Footer,
  },
  mounted() {
    this.$ga.page("/bicycle-app");
  },
};
</script>
